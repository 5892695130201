import request from '@/utils/request'

export async function getGroupList() {
  return request({
    url: '/groups',
    method: 'get',
  })
}

export async function getGroupCountList() {
  return request({
    url: '/groups.count',
    method: 'get',
  })
}

export async function deleteGroup(groupId) {
  return request({
    url: '/groups/' + groupId,
    method: 'delete',
  })
}

export async function modifyGroup(groupId, data) {
  return request({
    url: '/groups/' + groupId,
    method: 'patch',
    data,
  })
}

export async function createGroup(data) {
  return request({
    url: '/groups',
    method: 'post',
    data,
  })
}
