// import { title, titleReverse, titleSeparator } from '@/config'
import { title } from '@/config'

/**
 * @author noname
 * @description 设置标题
 * @param pageTitle
 * @returns {string}
 */
// export default function getPageTitle(pageTitle) {
//   let newTitles = []
//   if (pageTitle) newTitles.push(pageTitle)
//   if (title) newTitles.push(title)
//   if (titleReverse) newTitles = newTitles.reverse()
//   return newTitles.join(titleSeparator)
// }

export default function getPageTitle() {
  return title
}
