import request from '@/utils/request'

export async function getCaseList() {
  return request({
    url: '/subjects',
    method: 'get',
  })
}

export async function modifyCaseInfo(data) {
  return request({
    url: '/subjects',
    method: 'patch',
    data,
  })
}

export async function createCase(data) {
  return request({
    url: '/subjects',
    method: 'post',
    data,
  })
}

export async function getCase(fid) {
  return request({
    url: '/subjects/fid/' + fid,
    method: 'get',
  })
}

export async function deleteCase(fid) {
  return request({
    url: '/subjects/fid/' + fid,
    method: 'delete',
  })
}
