import axios from 'axios'
import CryptoJS from 'crypto-js' // 確保安裝了 crypto-js 套件
import { baseURL, contentType, requestTimeout } from '@/config'

const secretKey = process.env.VUE_APP_OTP_SECRET

// console.log(secretKey)
console.log(process.env.VUE_APP_BASE_URL)

function generateHMACSignature(secretKey, message) {
  return CryptoJS.HmacSHA256(message, secretKey).toString(CryptoJS.enc.Hex)
}

const axiosInst = axios.create({
  baseURL,
  timeout: requestTimeout,
  headers: {
    'Content-Type': contentType,
  },
})

axiosInst.interceptors.request.use(
  (config) => {
    console.log('axiosInst.interceptors.request.use')
    // 請求方法
    const method = config.method.toUpperCase() // Axios 方法小寫，轉大寫以一致
    // const method = config.method
    // 請求路徑
    const url = new URL(config.url, config.baseURL) // 獲取完整路徑
    const pathArray = url.pathname.split('/').filter(Boolean) // 轉成路徑陣列
    const path = '/api/' + pathArray.join('/') // 重新組合成完整路徑

    // 請求 body，若有
    let body = ''
    if (config.data) {
      body = JSON.stringify(config.data).replace(/\s+/g, '') // 移除空白
    }

    // 生成時間戳
    const timestamp = '1726200671927' // Date.now().toString()

    // 生成 HMAC 簽名
    const message = `${method}${path}${body}${timestamp}`
    console.log('Method:', method)
    console.log('Path:', path)
    console.log('Body:', body)
    console.log('Timestamp:', timestamp)
    console.log('Message:', message)
    const signature = generateHMACSignature(secretKey, message)

    // 加入 x-timestamp header
    config.headers['x-timestamp'] = timestamp

    // 加入 x-signature header
    config.headers['x-signature'] = signature

    console.log('Request:', config)
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosInst.interceptors.response.use((response) => {
  // 攔截器邏輯
  console.log(response)
  return response
})

export async function getStationInfo(stationId) {
  return axiosInst({
    url: `/organization/${stationId}`,
    method: 'get',
  })
}

export async function getOtp(data) {
  return axiosInst({
    url: `/otp`,
    method: 'post',
    data,
  })
}

export async function verifyOtp(data) {
  return axiosInst({
    url: `/epass/verifyotp`,
    method: 'post',
    data,
  })
}
