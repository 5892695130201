import { createI18n } from 'vue-i18n'
import tw from './zh-TW'
import en from './en-US'

//注册i8n实例并引入语言文件
const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en', // set fallback locale
  messages: {
    tw: tw,
    en: en,
  },
})

export default i18n //将i18n暴露出去，在main.js中引入挂载
