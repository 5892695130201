import request from '@/utils/request'
//import { tokenName } from '@/config'

export async function login(data) {
  return request({
    url: '/user.authenticate',
    method: 'post',
    data,
  })
}

export async function getWebConfig(account) {
  const params = new URLSearchParams()
  params.append('account', account)
  return request({
    url: '/web.configurations',
    method: 'get',
    params,
  })
}

export async function getHasHealthStations() {
  return request({
    url: '/hasHealthStations',
    method: 'get',
  })
}

// export async function socialLogin(data) {
//   return request({
//     url: '/socialLogin',
//     method: 'post',
//     data,
//   })
// }

//// This case does not need get info,  get user info when login
////
// export function getUserInfo(accessToken) {
//   //此处为了兼容mock.js使用data传递accessToken，如果使用mock可以走headers
//   return request({
//     url: '/userInfo',
//     method: 'post',
//     data: {
//       [tokenName]: accessToken,
//     },
//   })
// }

// export function logout() {
//   return request({
//     url: '/logout',
//     method: 'post',
//   })
// }

// export function register() {
//   return request({
//     url: '/register',
//     method: 'post',
//   })
// }
