import request from '@/utils/request'

export async function getGroupBySubjectsAVG(data) {
  return request({
    url: '/measurements/query/groupBySubjectsAVG',
    method: 'post',
    data,
  })
}

export async function getDealerGroupBySubjectsAVG(data) {
  return request({
    url: `/dealer.measurements/query/groupBySubjectsAVG/${data.code}`,
    method: 'post',
    data,
  })
}

export async function getGroupBySubjectsAVGDetail(data) {
  return request({
    url: '/measurements/web/query/detailFromAVG',
    method: 'post',
    data,
  })
}

export async function getMeasurementsById(data) {
  // {
  //   startTs:
  //   endTs:
  //   fid:
  //   pageNum:
  //   pageSize:
  //   type:
  // }
  ///api/measurements / fid / 1 / 1606924800000 / 1629993600000 / 1 / 10
  let url = `/measurements/fid/${data.fid}/${data.startTs}/${data.endTs}/${data.pageNum}/${data.pageSize}`
  if (data.type) {
    url = url + `?t=${data.type}`
  }
  return request({
    url: url,
    method: 'get',
  })
}

// GET量測平均值
export async function getMeasurementsAvg(data) {
  // {
  //   startTs:
  //   endTs:
  //   fid:
  //   pageNum:
  //   pageSize:
  // }
  ///api/measurements / fid / 1 / 1606924800000 / 1629993600000 / 1 / 10
  let url = `/measurements/avg/fid/${data.fid}/${data.startTs}/${data.endTs}`
  return request({
    url: url,
    method: 'get',
  })
}

// GET量測次數與異常百分比
export async function getMeasurementsAbnormalRate(data) {
  // {
  //   startTs:
  //   endTs:
  //   fid:
  //   pageNum:
  //   pageSize:
  // }
  ///api/measurements / fid / 1 / 1606924800000 / 1629993600000 / 1 / 10
  let url = `/measurements/abnormalRate/fid/${data.fid}/${data.startTs}/${data.endTs}`
  return request({
    url: url,
    method: 'get',
  })
}

export async function getMeasurementsExport(params) {
  let url = `/measurements/export`
  return request({
    url: url,
    method: 'get',
    responseType: 'blob',
    params,
  })
}

export async function getDealerMeasurementsExport(params) {
  let url = `dealer.measurements/client.export`
  return request({
    url: url,
    method: 'get',
    responseType: 'blob',
    params,
  })
}
