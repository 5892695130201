import {
  getMeasureThreshold,
  putMeasureThreshold,
  patchMeasureThreshold,
} from '@/api/measureThreshold'

const state = () => ({
  measureThreshold: [],
})

const getters = {
  measureThreshold: (state) => state.measureThreshold,
}

const mutations = {
  setMeasureThreshold(state, threshold) {
    state.measureThreshold = threshold
  },
}

const actions = {
  async getMeasureThreshold({ commit }) {
    try {
      const data = await getMeasureThreshold()
      if (data.ok === true && data.measureStandards) {
        commit('setMeasureThreshold', data.measureStandards)
      } else {
        commit('setMeasureThreshold', [])
      }
    } catch (e) {
      commit('setMeasureThreshold', [])
      console.log('exception')
      console.log(e)
    }
  },
  async putMeasureThreshold({ dispatch }, postData) {
    try {
      const data = await putMeasureThreshold(postData)
      if (data.ok === true) {
        dispatch('getMeasureThreshold')
      } else {
        //commit('setMeasureThreshold', [])
      }
    } catch (e) {
      //commit('setMeasureThreshold', [])
      console.log('exception')
      console.log(e)
    }
  },
  async patchMeasureThreshold({ commit }, postData) {
    try {
      const data = await patchMeasureThreshold(postData)
      if (data.ok === true && data.measureStandards) {
        commit('setMeasureThreshold', data.measureStandards)
      } else {
        //commit('setMeasureThreshold', [])
      }
    } catch (e) {
      //commit('setMeasureThreshold', [])
      console.log('exception')
      console.log(e)
    }
  },
}

export default { state, getters, mutations, actions }
