import {
  getMeasuredClassifiedAbnormal,
  getMeasuredVisits,
  getDealerAbnormalPie,
  getDealerBarPerType,
  getDealerDistrictAbnormal,
  getDealerClientAbnormal,
  getDealerClient,
} from '@/api/statistics'

const state = () => ({
  measuredVisits: {},
  measuredClassifiedAbnormal: [],
  dealerAbnormalPie: {},
  dealerBarPerType: [],
  dealerDistrictAbnormal: [],
  dealerClientAbnormal: [],
  dealerQueryTimeCondition: {},
  dealerClient: [],
})

const getters = {
  measuredVisits: (state) => state.measuredVisits,
  measuredClassifiedAbnormal: (state) => state.measuredClassifiedAbnormal,
  dealerAbnormalPie: (state) => state.dealerAbnormalPie,
  dealerBarPerType: (state) => state.dealerBarPerType,
  dealerDistrictAbnormal: (state) => state.dealerDistrictAbnormal,
  dealerClientAbnormal: (state) => state.dealerClientAbnormal,
  dealerQueryTimeCondition: (state) => state.dealerQueryTimeCondition,
  dealerClient: (state) => state.dealerClient,
}

const mutations = {
  setMeasuredVisits(state, measuredVisits) {
    state.measuredVisits = measuredVisits
  },
  setMeasuredClassifiedAbnormal(state, measuredClassifiedAbnormal) {
    state.measuredClassifiedAbnormal = measuredClassifiedAbnormal
  },

  setDealerAbnormalPie(state, dealerAbnormalPie) {
    state.dealerAbnormalPie = dealerAbnormalPie
  },

  setDealerBarPerType(state, dealerBarPerType) {
    state.dealerBarPerType = dealerBarPerType
  },

  setDealerDistrictAbnormal(state, dealerDistrictAbnormal) {
    state.dealerDistrictAbnormal = dealerDistrictAbnormal
  },

  setDealerClientAbnormal(state, dealerClientAbnormal) {
    state.dealerClientAbnormal = dealerClientAbnormal
  },

  setDealerQueryTimeCondition(state, dealerQueryTimeCondition) {
    state.dealerQueryTimeCondition = dealerQueryTimeCondition
  },

  setDealerClient(state, dealerClient) {
    state.dealerClient = dealerClient
  },
}

const actions = {
  async refreshMeasuredVisits({ commit }, params) {
    try {
      const data = await getMeasuredVisits(params)
      commit('setMeasuredVisits', data)
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
  async refreshMeasuredClassifiedAbnormal({ commit }, params) {
    try {
      const data = await getMeasuredClassifiedAbnormal(params)
      if (data.ok === true && data.data) {
        commit('setMeasuredClassifiedAbnormal', data.data)
      } else {
        commit('setMeasuredClassifiedAbnormal', [])
      }
    } catch (e) {
      commit('setMeasuredClassifiedAbnormal', [])
      console.log('exception')
      console.log(e)
    }
  },
  async getDealerAbnormalPie({ commit }, params) {
    try {
      const data = await getDealerAbnormalPie(params)
      commit('setDealerAbnormalPie', data?.result)
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
  async getDealerBarPerType({ commit }, params) {
    try {
      const data = await getDealerBarPerType(params)
      commit('setDealerBarPerType', data?.result)
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },

  async getDealerDistrictAbnormal({ commit }, params) {
    try {
      commit('setDealerQueryTimeCondition', params)
      const data = await getDealerDistrictAbnormal(params)
      commit('setDealerDistrictAbnormal', data?.result)
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },

  clearDealerDistrictAbnormalData({ commit }) {
    try {
      commit('setDealerDistrictAbnormal', [])
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },

  async getDealerClientAbnormal({ commit }, params) {
    try {
      commit('setDealerQueryTimeCondition', params)
      const data = await getDealerClientAbnormal(params)
      commit('setDealerClientAbnormal', data?.result)
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },

  clearDealerClientAbnormalData({ commit }) {
    try {
      commit('setDealerClientAbnormal', [])
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },

  async getDealerClient({ commit }) {
    try {
      const data = await getDealerClient()
      commit('setDealerClient', data?.result)
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },

  clearDealerClient({ commit }) {
    try {
      commit('setDealerDistrictAbnormal', [])
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
}

export default { state, getters, mutations, actions }
