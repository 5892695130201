import request from '@/utils/request'

export async function getMeasuredVisits(params) {
  //console.log(params)
  return request({
    url: '/statistics.measuredVisits',
    method: 'get',
    params,
  })
}

export async function getMeasuredClassifiedAbnormal(params) {
  //console.log(params)
  return request({
    url: '/statistics.measuredClassifiedAbnormal',
    method: 'get',
    params,
  })
}

export async function getDealerAbnormalPie(data) {
  // {
  //   startTs:
  //   endTs:
  // }
  //console.log(params)
  return request({
    url: `/statistics.dealer.abnormalPie/${data.startTs}/${data.endTs}`,
    method: 'get',
  })
}

export async function getDealerBarPerType(data) {
  //console.log(params)
  return request({
    url: `/statistics.dealer.bar.per.type/${data.startTs}/${data.endTs}`,
    method: 'get',
  })
}

export async function getDealerDistrictAbnormal(data) {
  //console.log(params)
  return request({
    url: `/statistics.dealer.district.abnormal/${data.startTs}/${data.endTs}`,
    method: 'get',
  })
}

export async function getDealerClientAbnormal(data) {
  //console.log(params)
  return request({
    url: `/statistics.dealer.client.abnormal/${data.city}/${data.district}/${data.startTs}/${data.endTs}`,
    method: 'get',
  })
}

export async function getDealerClient() {
  return request({
    url: `/dealer.client`,
    method: 'get',
  })
}

export async function getDealerDistrictExport(data) {
  // https://staging.api.ulifecloud.com/api/statistics.dealer.dashboard/:city/:district/:start/:end/:lang
  const url = `/statistics.dealer.dashboard/${data.city}/${data.district}/${data.startTs}/${data.endTs}/${data.lang}`
  console.log(url)
  return request({
    url: url,
    method: 'get',
    responseType: 'blob',
  })
}
