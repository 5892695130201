import request from '@/utils/request'

export async function getMeasureThreshold() {
  return request({
    url: '/measureStandards',
    method: 'get',
  })
}

export async function putMeasureThreshold(data) {
  return request({
    url: '/measureStandards',
    method: 'put',
    data,
  })
}

export async function patchMeasureThreshold(data) {
  return request({
    url: '/measureStandards',
    method: 'patch',
    data,
  })
}

// {
//   type: 'bloodGlucose',
//   unit: 'mg/dL',
// }
// /api/measureStandards/:mType/:mUnit
export async function getMeasureStandards(data) {
  const typeEncode = encodeURIComponent(data.type)
  const unitEncode = encodeURIComponent(data.unit)
  return request({
    url: `/measureStandards/${typeEncode}/${unitEncode}`,
    method: 'get',
  })
}
