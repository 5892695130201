import {
  getCaseList,
  modifyCaseInfo,
  createCase,
  getCase,
  deleteCase,
} from '@/api/case'

const state = () => ({
  cases: [],
  currentCase: {},
  filterFlag: 'Activate',
})

const getters = {
  cases: (state) => state.cases,
  currentCase: (state) => state.currentCase,
  filterFlag: (state) => state.filterFlag,
}

const mutations = {
  setFilterFlag(state, filterFlag) {
    state.filterFlag = filterFlag
    localStorage.setItem('storeCase', JSON.stringify(state))
  },
  setCase(state, cases) {
    state.cases = cases
    localStorage.setItem('storeCase', JSON.stringify(state))
  },
  setCurrentCase(state, currentCase) {
    state.currentCase = currentCase
    localStorage.setItem('storeCase', JSON.stringify(state))
  },
}

const actions = {
  async getCaseList({ state, commit }, filterFlag) {
    try {
      // ['Activate', 'Suspended', 'All']
      if (filterFlag === undefined) {
        filterFlag = state.filterFlag
      }
      const data = await getCaseList()
      if (data.ok === true && data.subjects) {
        if (filterFlag === 'Activate') {
          data.subjects = data.subjects.filter(
            (item) => item.state === 'ACTIVATED'
          )
          commit('setFilterFlag', 'Activate')
        } else if (filterFlag === 'Suspended') {
          data.subjects = data.subjects.filter(
            (item) => item.state === 'SUSPENDED'
          )
          commit('setFilterFlag', 'Suspended')
        } else {
          // All
          commit('setFilterFlag', 'All')
        }

        commit('setCase', data.subjects)
      } else {
        commit('setCase', [])
      }
    } catch (e) {
      commit('setCase', [])
      console.log('exception')
      console.log(e)
    }
  },
  async modifyCaseInfo({ dispatch }, postBody) {
    try {
      const data = await modifyCaseInfo(postBody)
      if (data.ok === true) {
        dispatch('getCaseList')
      }
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
  async createCase({ dispatch }, postBody) {
    try {
      const data = await createCase(postBody)
      if (data.ok === true) {
        dispatch('getCaseList')
      }
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
  clearCurrentCaseData({ commit }) {
    try {
      commit('setCurrentCase', {})
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
  async getCase({ commit }, fid) {
    try {
      const data = await getCase(fid)
      if (data.ok === true && data.result) {
        commit('setCurrentCase', data.result)
      } else {
        commit('setCurrentCase', {})
      }
    } catch (e) {
      commit('setCurrentCase', {})
      console.log('exception')
      console.log(e)
    }
  },
  async deleteCase({ dispatch }, fid) {
    try {
      const data = await deleteCase(fid)
      if (data.ok === true) {
        dispatch('getCaseList')
      }
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
  async clearCaseData({ commit }) {
    try {
      commit('setCase', [])
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
}

export default { state, getters, mutations, actions }
