<template>
  <div class="vab-avatar">
    <a-dropdown>
      <span class="ant-dropdown-link">
        <a-avatar :src="avatar" />
        {{ account }}
        <DownOutlined />
      </span>
      <template v-slot:overlay>
        <a-menu>
          <a-menu-item @click="logout">
            {{ $t('RightMenu.logout') }}
          </a-menu-item>
          <a-menu-item
            @click="changeTranditionalChinese"
            v-if="isWebConfigShowChangeLanguage"
            :disabled="!isWebConfigEnableChangeLanguage"
          >
            {{ $t('RightMenu.langCht') }}
          </a-menu-item>
          <a-menu-item
            @click="changeEnglish"
            v-if="isWebConfigShowChangeLanguage"
            :disabled="!isWebConfigEnableChangeLanguage"
          >
            {{ $t('RightMenu.langEng') }}
          </a-menu-item>
          <!-- <a-menu-item
            @click="clickExport"
            v-if="isWebConfigShowExport"
            :disabled="!isWebConfigEnableExport"
          >
            {{ $t('HR001.export') }}
          </a-menu-item> -->
        </a-menu>
      </template>
    </a-dropdown>
  </div>
</template>

<script>
  import { recordRoute } from '@/config'
  import { DownOutlined } from '@ant-design/icons-vue'
  // import { getMeasurementsExport } from '@/api/measurement'

  import { mapActions, mapGetters } from 'vuex'
  export default {
    name: 'VabAvatar',
    components: { DownOutlined },
    data() {
      return {}
    },
    computed: {
      ...mapGetters({
        avatar: 'user/avatar',
        account: 'user/account',
        webConfig: 'user/webConfig',
      }),
      isWebConfigShowChangeLanguage() {
        let retData = false
        this.webConfig.forEach((conf) => {
          if (conf.id === 'lang' && conf.privilege.show === true) {
            retData = true
          }
        })
        return retData
      },
      isWebConfigEnableChangeLanguage() {
        let retData = false
        this.webConfig.forEach((conf) => {
          if (conf.id === 'lang' && conf.privilege.enable === true) {
            retData = true
          }
        })
        return retData
      },
      isWebConfigShowExport() {
        let retData = false
        this.webConfig.forEach((conf) => {
          if (conf.id === 'export' && conf.privilege.show === true) {
            retData = true
          }
        })
        return retData
      },
      isWebConfigEnableExport() {
        let retData = false
        this.webConfig.forEach((conf) => {
          if (conf.id === 'export' && conf.privilege.enable === true) {
            retData = true
          }
        })
        return retData
      },
    },
    methods: {
      async logout() {
        await this.$store.dispatch('user/logout')
        await localStorage.clear()
        if (recordRoute) {
          const fullPath = this.$route.fullPath
          this.$router.push(`/login?redirect=${fullPath}`)
          this.$router.go(0)
        } else {
          this.$router.push('/login')
          this.$router.go(0)
        }
      },
      ...mapActions({
        changeLanguage: 'settings/changeLanguage',
        setLoadingStatus: 'settings/setLoadingStatus',
        setLoadingText: 'settings/setLoadingText',
      }),
      changeTranditionalChinese() {
        this.$i18n.locale = 'tw'
        this.changeLanguage(this.$i18n.locale)
        window.location.reload()
        //this.$router.go()
      },
      changeEnglish() {
        this.$i18n.locale = 'en'
        this.changeLanguage(this.$i18n.locale)
        window.location.reload()
        //this.$router.go()
      },
      // async clickExport() {
      //   this.setLoadingText(this.$t('MO001.reportDownloading'))
      //   this.setLoadingStatus(true)
      //   try {
      //     const params = {
      //       // startTime: this.fromDate,
      //       // endTime: this.toDate,
      //     }
      //     const respData = await getMeasurementsExport(params)
      //     // console.log(respData)
      //     // console.log(respData.headers['Content-Disposition'])
      //     // console.log(respData.headers['content-disposition'])

      //     let fileName = 'Report.xlsx'
      //     let contentDisposition = respData.headers['content-disposition']
      //     if (!contentDisposition) {
      //       contentDisposition = respData.headers['Content-Disposition']
      //     }
      //     if (contentDisposition) {
      //       fileName = contentDisposition.split(' ')[1].replace('filename=', '')
      //     }

      //     const url = window.URL.createObjectURL(new Blob([respData.data]))
      //     const link = document.createElement('a')
      //     link.href = url
      //     link.setAttribute('download', fileName)
      //     document.body.appendChild(link)
      //     link.click()
      //   } catch (e) {
      //     console.log(e)
      //   }
      //   this.setLoadingStatus(false)
      // },
    },
  }
</script>
<style lang="less">
  .vab-avatar {
    .ant-dropdown-link {
      display: block;
      min-height: 64px;
      cursor: pointer;
    }
  }
</style>
