import {
  getGroupList,
  getGroupCountList,
  deleteGroup,
  createGroup,
  modifyGroup,
} from '@/api/group'

const state = () => ({
  groups: [],
  groupsCount: [],
})

const getters = {
  groups: (state) => state.groups,
  groupsCount: (state) => state.groupsCount,
}

const mutations = {
  setGroup(state, groups) {
    state.groups = groups
  },
  setGroupCount(state, groupsCount) {
    state.groupsCount = groupsCount
  },
}

const actions = {
  async getGroupList({ commit }) {
    try {
      const data = await getGroupList()
      if (data.ok === true && data.groups) {
        commit('setGroup', data.groups)
      } else {
        commit('setGroup', [])
      }
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
  async getGroupCountList({ commit }) {
    try {
      const data = await getGroupCountList()
      if (data.ok === true && data.groups) {
        commit('setGroupCount', data.groups)
      } else {
        commit('setGroupCount', [])
      }
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
  async deleteGroup({ dispatch }, groupId) {
    try {
      const data = await deleteGroup(groupId)
      if (data.ok === true) {
        dispatch('getGroupCountList')
      }
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
  async createGroup({ dispatch }, postBody) {
    try {
      const data = await createGroup(postBody)
      if (data.ok === true) {
        dispatch('getGroupCountList')
      }
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
  async modifyGroup({ dispatch }, { groupId, patchBody }) {
    try {
      const data = await modifyGroup(groupId, patchBody)
      if (data.ok === true) {
        dispatch('getGroupCountList')
      }
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  },
}

export default { state, getters, mutations, actions }
